<template>
  <div class="member_boxs">
    <div class="border">
      <el-form :inline="true" :model="data">
        <el-form-item label="您的名字">
          <el-input v-model="data.name" class="name" placeholder="请输入..."></el-input>
        </el-form-item>
        <el-form-item label="您的手机号">
          <el-input v-model="data.mobile" class="tel" placeholder="请输入..."></el-input>
        </el-form-item>
        <el-form-item label="您公司的名称">
          <el-input v-model="data.company" class="company" placeholder="请输入..."></el-input>
        </el-form-item>
        <el-form-item label="您的建议">
          <el-input v-model="data.content" class="message" type="textarea" placeholder="请输入..." rows="4"></el-input>
        </el-form-item>
        <div>
          <el-button class="btn" type="primary" @click="submit">发送</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request'
import { Message } from 'element-ui'
export default {
  name: 'Message',
  data() {
    return {
      data: {
        name: '',
        mobile: '',
        company: '',
        content: '',
      }
    }
  },
  methods:{
    submit(){
        if(this.data.name == ''){
            Message({
                message: '请输入您的名字',
                type: 'warning',
                duration: 5 * 1000
            })
            return
        }
        if(this.data.mobile == ''){
            Message({
                message: '请输入您的手机号',
                type: 'warning',
                duration: 5 * 1000
            })
            return
        }
        var reg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (reg.test(this.data.mobile) == false) {
            Message({
                message: '请输入正确的手机号',
                type: 'warning',
                duration: 5 * 1000
            })
            return
        }
        request.post('/in/website/leaveword',this.data).then(() => {
            this.data.name = ''
            this.data.mobile = ''
            this.content = ''
            Message({
                message: '已收到您的申请',
                type: 'success',
                duration: 5 * 1000
            })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.member_boxs {
  .border {
    width: 570px;
    // height: 680px;
    margin-top: 43px;
    .name,
    .tel,
    .company,
    .message {
      width: 557px;
      height: 48px;
    }
    .btn {
      width: 290px;
      height: 48px;
      background: #333333;
      border-radius: 4px;
      font-size: 24px;
      border: none;
      margin-top: 70px;
      margin-left: 143px;
    }
  }
}
</style>
<style lang="less">
.member_boxs {
  .el-form-item__label {
    width: 557px;
    font-size: 20px;
    color: #333333;
    text-align: left;
    padding-right: 0;
    font-weight: 700;
  }
  .el-textarea__inner {
    resize: none;
  }
}
</style>